<template>
  <v-dialog v-model="dialog" content-class="rounded-0 dialogPopUp">
    <template slot:defautl="" >
      <div style="position: relative;">
        <a href="https://peru.helvex.la">
          <v-img :src="img" max-height="90vh" :width="widthImg" contain :aspect-ratio="aspectRatio"></v-img>
        </a>
        <v-btn fab dark x-small color="" class="iconClosePopUp" @click="dialog=false">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
  </v-dialog>
</template>

<script>
const images = {
  desk: require("@/assets/img/popup/Pop-up-desktop.jpg"),
  phone: require("@/assets/img/popup/pop-up-movil.jpg")
}
export default {
  name: "hx-pop-up",
  data() {
    return {
      dialog: true,
      img: null,
      widthImg: '100%',
      heightImg: 'auto',
      aspectRatio: 1
    };
  },
  mounted(){
    this.changePopUp();

    window.addEventListener('resize',()=>{
      this.changePopUp()
    })
  },
  methods:{
    changePopUp(){
      if(window.innerWidth < 800){        
        this.img = images.phone
        this.widthImg =  (window.innerHeight*0.9)*(240/600)
        this.heightImg = '500px'
        this.aspectRatio = 240/600
      }else{
        this.img = images.desk
        this.widthImg =  '80vw'
        this.heightImg = 'auto'
        this.aspectRatio = 1071/471
      }
    }
  }
};
</script>

<style lang="scss">
.dialogPopUp{
  overflow-y: inherit !important;
  padding: 0px 20px;
  box-shadow: none !important;
  max-height: inherit !important;
  width: fit-content !important;
  .iconClosePopUp{
    position: absolute;
    top: -15px;
    right: -15px;
  }
}
</style>