<template>
  <v-app style="width: 100vw">
    <v-main>
      <hx-carrusel/>
      <hx-content/>
      <hx-pop-up/>
      <hx-footer/>
    </v-main>
  </v-app>
</template>

<script>
import HxCarrusel from "./components/hx-carrusel.vue"
import HxContent from "./components/hx-content.vue"
import HxPopUp from "./components/hx-pop-up"
import HxFooter from "./components/hx-footer"
export default {
  name: 'App',

  components: {
    HxCarrusel,
    HxContent,
    HxPopUp,
    HxFooter,
  },

  data: () => ({
    //
  }),
};
</script>
