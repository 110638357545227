<template>
  <div class="containerFooter">
    <span>{{ year }} &copy; Helvex</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
.containerFooter {
  background-color: black;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5px 0px;
  span {
    width: 100%;
    text-align: center;
    color: white;
    display: block;
  }
}
@media (max-width: 800px) {
  .containerFooter {
    display: none;
  }
}
</style>