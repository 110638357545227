<template>
  <div class="principalContainer">
    <div>
      <v-row class="firstRow" justify="center" align="center">
        <v-col cols="12">
          <v-row>
            <v-col class="colInfo">
              <v-row dense align="center" style="height: 100%">
                <v-col cols="12">
                  <v-img
                    src="@/assets/img/logo.webp"
                    alt="Helvex logo"
                    contain
                    width="40%"
                    class="mx-auto"
                  ></v-img>
                  <div class="containerText">
                    <h3>Bienvenido a Helvex International</h3>
                    <p>
                      La trayectoria de Helvex en el campo internacional, se
                      remonta a más de 25 años cuando incursionó exitosamente
                      con la venta de sus productos en nuevos mercados. Desde
                      entonces el crecimiento de sus exportaciones ha sido
                      extraordinario. Gracias a su avanzada tecnología e
                      innovadores diseños, Grupo Helvex ha logrado una
                      importante presencia de sus productos en más de 22 países
                      en América.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="colCountries">
              <v-row justify="center">
                <v-col
                  class="colSingleCountry"
                  v-for="(country, key) in countriesSort"
                  :key="key"
                >
                  <v-row dense width="100%">
                    <v-col cols="auto">
                      <a :href="country.url">
                        <v-img :src="country.img" width="40px"></v-img>
                      </a>
                    </v-col>
                    <v-col cols="auto">
                      <a :href="country.url" class="textCountry">
                        <span>{{ country.name }}</span>
                        <span v-if="country.tag" class="tag">
                          {{ country.tag }}
                        </span>
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "hx-content",
  data() {
    return {
      countries: [
        {
          name: "Chile",
          img: require("@/assets/img/countries/chile.png"),
          url: "https://chile.helvex.la",
        },
        {
          name: "Nicaragua",
          img: require("@/assets/img/countries/nicaragua.png"),
          url: "https://helvex.la/latam",
        },
        {
          name: "Colombia",
          img: require("@/assets/img/countries/colombia.png"),
          url: "https://helvex.com/co",
        },
        {
          name: "Panamá",
          img: require("@/assets/img/countries/panama.png"),
          url: "https://helvex.com/pa",
        },
        {
          name: "Costa Rica",
          img: require("@/assets/img/countries/costa-rica.png"),
          url: "https://helvex.com/cr",
        },
        {
          name: "Perú",
          img: require("@/assets/img/countries/peru.png"),
          url: " https://helvex.com/pe",
          tag: "TIENDA EN LÍNEA",
        },
        {
          name: "El Salvador",
          img: require("@/assets/img/countries/el-salvador.png"),
          url: "https://helvex.com/sv",
        },
        {
          name: "Puerto Rico",
          img: require("@/assets/img/countries/puerto-rico.png"),
          url: "https://helvex.la/latam",
        },
        {
          name: "Guatemala",
          img: require("@/assets/img/countries/guatemala.png"),
          url: "https://helvex.com/gt",
        },
        {
          name: "Republica dominicana",
          img: require("@/assets/img/countries/rep-dominicana.png"),
          url: "https://helvex.com/do",
        },
        {
          name: "Honduras",
          img: require("@/assets/img/countries/honduras.png"),
          url: "https://helvex.com/hn",
        },
        {
          name: "U.S.A.",
          img: require("@/assets/img/countries/eua.png"),
          url: "https://helvex.la/latam",
        },
        {
          name: "México",
          img: require("@/assets/img/countries/mexico.png"),
          url: "https://helvex.com.mx",
        },
        {
          name: "Venezuela",
          img: require("@/assets/img/countries/venezuela.png"),
          url: "https://helvex.la/latam",
        },
      ],
    };
  },
  computed: {
    countriesSort() {
      function SortArray(x, y) {
        return x.name.localeCompare(y.name);
      }

      return [...this.countries].sort(SortArray);
    },
  },
};
</script>

<style lang="scss" scope>
.principalContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  & > div {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    max-width: 1300px;
  }
  .firstRow {
    height: 100%;
  }
}
.textCountry {
  font-size: 20px;
  font-weight: normal;
  margin-left: 10px;
  vertical-align: middle;
  line-height: 38px;
  text-decoration: none;
  color: black !important;
  .tag {
    background-color: red;
    color: white;
    padding: 1px 2px;
    font-size: 10px;
    position: relative;
    top: -11px;
    left: 2px;
  }
}
.containerText {
  width: 80%;
  margin: 10px auto;
  h3 {
    text-align: center;
  }
  p {
    text-align: center;
    margin-top: 10px;
  }
}
.colInfo,
.colCountries,
.colSingleCountry {
  flex: 0 0 50% !important;
  max-width: 50% !important;
}

@media (max-width: 1100px) {
  .textCountry {
    font-size: 15px;
  }
}

@media (max-width: 950px) {
  .textCountry {
    font-size: 12px;
  }
}

@media (max-width: 800px) {
  .colInfo {
    widows: 100%;
    height: 100vw;
    padding: 5px 0px;
  }
  .colCountries {
    margin-top: 40px;
  }
  .colInfo,
  .colCountries,
  .colSingleCountry {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .colSingleCountry {
    flex: 0 0 60% !important;
    max-width: 100% !important;
  }

  .textCountry {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .textCountry {
    font-size: 15px;
  }
  .containerText {
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
    }
  }
}
</style>
