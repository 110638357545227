<template>
  <v-carousel
    :prev-icon="false"
    :next-icon="false"
    :hide-delimiters="true"
    :width="width"
    :height="height"
    cycle
    class="carrusel"
  >
    <v-carousel-item
      v-for="(img, i) in covers"
      :key="i"
      :src="img"
      reverse-transition="fade-transition"
      transition="fade-transition"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
const images = {
  desk: [
    require("@/assets/img/background/desk/Fondo-desktop.jpg")
  ],
  phone: [
    require("@/assets/img/background/phone/Fondo-movil.jpg")
  ],
}
export default {
  name: "hx-carousel",
  data() {
    return {
      covers: images.desk,
      height: null,
      width: null,
    };
  },
  mounted(){
    this.changeBackGroundImg()
    window.addEventListener('resize',()=>{
      this.changeBackGroundImg()
    })
  },
  methods:{
    changeBackGroundImg(){
      if (window.innerWidth < 800) {
        this.covers = images.phone
        this.width = '100vw'
        this.height = window.innerWidth
      }else{
        this.covers = images.desk
        this.width = '100%'
        this.height = '100vh'
      }
    }
  }
};
</script>

<style>
</style>